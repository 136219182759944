import * as React from 'react';
import Card from './card';


function getProjects() {
  return [
    {
      id: 'lagrange',
      title: 'Lagrange: AI-driven Scheduling for Restaurants',
      date: 'Apr, 2023',
      url: 'https://lagrangeai.com/services/scheduler',
      description: <p>Lagrange started as a staffing schedule generator for restaurants. The problem to solve is simple, schedule the right amount of staff at all times. This means forecasting how many customers will the restaurant receive per unit of time (the demand), how many customers each team configuration can handle (the capacity) and to use this information to build actual schedules ahead of time that supply enough team members in all areas to deliver to all customers while minimizing the payroll (the plan), all while respecting staff preferences and business rules (the constraints)</p>,
    },
    {
      id: 'auto-mock',
      title: 'Auto-Mock: A more flexible Δ-MOCK implementation',
      date: 'Aug, 2019',
      url: 'https://drive.google.com/file/d/1PxswOWiaEmGEzpImOxyTlGacoLjts44d/view?usp=sharing',
      description: <p>MSc Data Science dissertation. Developed further the <a href="https://ieeexplore.ieee.org/abstract/document/8004483">clustering algorithm Δ-MOCK</a>, this version removes some sensible parameters while keeping performace. My advisors were <a href="https://www.research.manchester.ac.uk/portal/richard.allmendinger.html">Richard Allmendinger</a> and <a href="https://www.linkedin.com/in/cameronshand/">Cameron Shand</a>.</p>,
    },
    {
      id: 'playground',
      title: 'Playground',
      url: 'https://github.com/luisds95/Playground',
      description: <p>Curated collection of notebooks and code files I have worked on while learning a wide range of data science subfields, such as <a href="https://github.com/luisds95/Playground/tree/master/Reinforcement%20Learning">Reinforcement Learning</a>, <a href="https://github.com/luisds95/Playground/tree/master/Natural%20Language%20Processing">Natural Language Processing</a>, <a href="https://github.com/luisds95/Playground/tree/master/Neural%20Networks">Deep Neural Networks</a>, <a href="https://github.com/luisds95/Playground/tree/master/Genetic%20Algorithms">Genetic Algorithms</a>, etc. Some of these are accompanied by a pdf and/or article.</p>,
    },
  ];
}


function Projects() {
  const allProjects = getProjects();
  const cards = allProjects.map((project) => Card(project));
  return (
    <section id='projects'>
      <h1>Projects</h1>
      {cards}
    </section>
  );
}

export default Projects;
