import * as React from 'react';
import * as aboutStyle from '../css/About.module.css';
import {StaticImage} from 'gatsby-plugin-image';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFile, faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {faTwitter, faGithub, faLinkedin} from '@fortawesome/free-brands-svg-icons';
import cv from '../assets/CV-Luis.pdf';

function About() {
  return (
    <section id='about'>
      <h1>About me</h1>
      <div className={aboutStyle.intro}>
        <div className={aboutStyle.introContent}>
          <p>
            Hello! I'm Luis Da Silva, originally from Venezuela and now based in the UK. My background is a blend of Economics and Data Science, but my career has taken an exciting turn towards Software Engineering and Entrepreneurship.
            <br />
            I'm the founder of <a href='http://lagrangeai.com/'>Lagrange Systems</a>, where we're using AI to revolutionize how restaurants manage pricing and staffing. Before that, I honed my technical skills as a <a href='https://www.linkedin.com/in/luis-da-silva/'>Tech Lead</a> at <a href='https://www.emishealth.com/'>EMIS Health</a>.
            <br />
            I thrive on challenges and I'm always on the lookout for intriguing problems to solve. And when I'm not immersed in code, you'll likely find me exploring the countryside on a run 😉.
          </p>
        </div>
        <div>
          <StaticImage src='../assets/profile.jpg' alt="Luis Da Silva" className={aboutStyle.luisPic} />
        </div>
      </div>
      <div className={aboutStyle.social}>
        <a className={aboutStyle.socialIcon} href='mailto:luisdanield@gmail.com'>{<FontAwesomeIcon icon={faEnvelope} />}</a>
        <a className={aboutStyle.socialIcon} href={cv}>{<FontAwesomeIcon icon={faFile} />}</a>
        <a className={aboutStyle.socialIcon} href='https://www.linkedin.com/in/luis-da-silva/'>{<FontAwesomeIcon icon={faLinkedin} />}</a>
        <a className={aboutStyle.socialIcon} href='https://github.com/luisds95'>{<FontAwesomeIcon icon={faGithub} />}</a>
        <a className={aboutStyle.socialIcon} href='https://twitter.com/lddsdp'>{<FontAwesomeIcon icon={faTwitter} />}</a>
      </div>
    </section>

  );
}

export default About;
