import * as React from 'react';
import * as appStyle from '../css/App.module.css';
import About from '../components/about';
import Articles from '../components/articles';
import Projects from '../components/projects';
import Layout from '../components/layout';

function App() {
  return (
    <Layout pageTitle="Home" >
      <div className={appStyle.AppBody}>
        <Articles />
        <hr className={appStyle.separator} />
        <Projects />
        <hr className={appStyle.separator} />
        <About />
      </div>
    </Layout>
  );
}

export default App;
